import { defineStore } from "pinia";

export const useAppStore = defineStore("appStore", () => {
  const footerText = ref<string>("");

  const getFooterText = computed<string>(() => footerText.value);

  function setFooterText(newText: string) {
    footerText.value = newText;
  }

  function resetFooterText() {
    footerText.value = "";
  }

  return {
    getFooterText,
    footerText,
    setFooterText,
    resetFooterText,
  };
});
